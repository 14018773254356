import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// All Routes (Without Tabs)
// Note (Found others routes at tabs/tabs-routing.module.ts)
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/cartonfly/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/cartonfly/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'store/:id',
    loadChildren: () =>
      import('./pages/cartonfly/home/home.module').then((m) => m.HomeModule),
  },
  //  { path: 'cartonfly', loadChildren: () => import('./pages/cartonfly/home/home.module').then(m => m.HomeModule) },
  //  { path: '', loadChildren: () => import('./pages/cartonfly/home/home.module').then(m => m.HomeModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
