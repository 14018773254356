export const username = 'cof';
export const password = 'cof@123!';
export const BasicAuth = 'Basic ' + btoa(username + ':' + password);
export const httpBasicAuthOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: BasicAuth,
  }),
};
import { HttpHeaders } from '@angular/common/http';
export const environment = {
  production: true,
  rootCloudUrl: 'https://us-central1-cartonfly-7ee9b.cloudfunctions.net/',
  globalPushNotificationsTopic: 'gogroceries_app',
  isWhiteLabeled: false,
  storeId: 'glambeautysupplies',
  payment_return_url: 'http://localhost:8100/payment-completed',
  // developer_id: 1538593642,
  //wipay_payment_gateway_url: 'https://wipayjm.com/v1/wordpress/gateway_live',
  developer_id: 1, //sanbox
  wipay_payment_gateway_url: 'https://sandbox.wipayfinancial.com/v1/gateway', //sanbox
};
export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
